import * as i0 from '@angular/core';
import { Injectable, InjectionToken, makeEnvironmentProviders, EventEmitter, LOCALE_ID, Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, Optional, Input, Output, NgModule } from '@angular/core';
import { formatDate, NgTemplateOutlet } from '@angular/common';
function CountdownComponent_Conditional_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c0 = a0 => ({
  $implicit: a0
});
function CountdownComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CountdownComponent_Conditional_0_ng_container_0_Template, 1, 0, "ng-container", 0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.render)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r0.i));
  }
}
function CountdownComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r1.i.text, i0.ɵɵsanitizeHtml);
  }
}
var CountdownStatus;
(function (CountdownStatus) {
  CountdownStatus[CountdownStatus["ing"] = 0] = "ing";
  CountdownStatus[CountdownStatus["pause"] = 1] = "pause";
  CountdownStatus[CountdownStatus["stop"] = 2] = "stop";
  CountdownStatus[CountdownStatus["done"] = 3] = "done";
})(CountdownStatus || (CountdownStatus = {}));
class CountdownTimer {
  constructor(ngZone) {
    this.ngZone = ngZone;
    this.fns = [];
    this.commands = [];
    this.nextTime = 0;
    this.ing = false;
  }
  start() {
    if (this.ing === true) {
      return;
    }
    this.ing = true;
    this.nextTime = +new Date();
    this.ngZone.runOutsideAngular(() => {
      this.process();
    });
  }
  process() {
    while (this.commands.length) {
      this.commands.shift()();
    }
    let diff = +new Date() - this.nextTime;
    const count = 1 + Math.floor(diff / 100);
    diff = 100 - diff % 100;
    this.nextTime += 100 * count;
    for (let i = 0, len = this.fns.length; i < len; i += 2) {
      let frequency = this.fns[i + 1];
      // 100/s
      if (0 === frequency) {
        this.fns[i](count);
        // 1000/s
      } else {
        // 先把末位至0，再每次加2
        frequency += 2 * count - 1;
        const step = Math.floor(frequency / 20);
        if (step > 0) {
          this.fns[i](step);
        }
        // 把末位还原成1
        this.fns[i + 1] = frequency % 20 + 1;
      }
    }
    if (!this.ing) {
      return;
    }
    setTimeout(() => this.process(), diff);
  }
  add(fn, frequency) {
    this.commands.push(() => {
      this.fns.push(fn);
      this.fns.push(frequency === 1000 ? 1 : 0);
      this.ing = true;
    });
    return this;
  }
  remove(fn) {
    this.commands.push(() => {
      const i = this.fns.indexOf(fn);
      if (i !== -1) {
        this.fns.splice(i, 2);
      }
      this.ing = this.fns.length > 0;
    });
    return this;
  }
  static {
    this.ɵfac = function CountdownTimer_Factory(t) {
      return new (t || CountdownTimer)(i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CountdownTimer,
      factory: CountdownTimer.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CountdownTimer, [{
    type: Injectable
  }], () => [{
    type: i0.NgZone
  }], null);
})();
const COUNTDOWN_CONFIG = new InjectionToken('COUNTDOWN_CONFIG');
function provideCountdown(config) {
  return makeEnvironmentProviders([{
    provide: COUNTDOWN_CONFIG,
    useValue: config
  }]);
}
class CountdownComponent {
  set config(i) {
    if (i.notify != null && !Array.isArray(i.notify) && i.notify > 0) {
      i.notify = [i.notify];
    }
    this._config = i;
  }
  get config() {
    return this._config;
  }
  constructor(locale, timer, cdr, ngZone, defCog) {
    this.locale = locale;
    this.timer = timer;
    this.cdr = cdr;
    this.ngZone = ngZone;
    this.defCog = defCog;
    this.frequency = 1000;
    this._notify = {};
    this.status = CountdownStatus.ing;
    this.isDestroy = false;
    this.i = {};
    this.left = 0;
    this.event = new EventEmitter();
  }
  /**
   * Start countdown, you must manually call when `demand: false`
   */
  begin() {
    this.status = CountdownStatus.ing;
    this.callEvent('start');
  }
  /**
   * Restart countdown
   */
  restart() {
    if (this.status !== CountdownStatus.stop) {
      this.destroy();
    }
    this.init();
    this.callEvent('restart');
  }
  /**
   * Stop countdown, must call `restart` when stopped, it's different from pause, unable to recover
   */
  stop() {
    if (this.status === CountdownStatus.stop) {
      return;
    }
    this.status = CountdownStatus.stop;
    this.destroy();
    this.callEvent('stop');
  }
  /**
   * Pause countdown, you can use `resume` to recover again
   */
  pause() {
    if (this.status === CountdownStatus.stop || this.status === CountdownStatus.pause) {
      return;
    }
    this.status = CountdownStatus.pause;
    this.callEvent('pause');
  }
  /**
   * Resume countdown
   */
  resume() {
    if (this.status === CountdownStatus.stop || this.status !== CountdownStatus.pause) {
      return;
    }
    this.status = CountdownStatus.ing;
    this.callEvent('resume');
  }
  callEvent(action) {
    this.event.emit({
      action,
      left: this.left,
      status: this.status,
      text: this.i.text
    });
  }
  init() {
    const config = this.config = {
      demand: false,
      leftTime: 0,
      format: 'HH:mm:ss',
      timezone: '+0000',
      formatDate: ({
        date,
        formatStr,
        timezone
      }) => {
        return formatDate(new Date(date), formatStr, this.locale, timezone || '+0000');
      },
      ...this.defCog,
      ...this.config
    };
    const frq = this.frequency = ~config.format.indexOf('S') ? 100 : 1000;
    this.status = config.demand ? CountdownStatus.pause : CountdownStatus.ing;
    this.getLeft();
    // bind reflow to me
    const _reflow = this.reflow;
    this.reflow = (count = 0, force = false) => _reflow.apply(this, [count, force]);
    if (Array.isArray(config.notify)) {
      config.notify.forEach(time => {
        if (time < 1) {
          throw new Error(`The notify config must be a positive integer.`);
        }
        time = time * 1000;
        time = time - time % frq;
        this._notify[time] = true;
      });
    }
    this.timer.add(this.reflow, frq).start();
    this.reflow(0, true);
  }
  destroy() {
    this.timer.remove(this.reflow);
    return this;
  }
  /**
   * 更新时钟
   */
  reflow(count = 0, force = false) {
    if (this.isDestroy) {
      return;
    }
    const {
      status,
      config,
      _notify
    } = this;
    if (!force && status !== CountdownStatus.ing) {
      return;
    }
    let value = this.left = this.left - this.frequency * count;
    if (value < 1) {
      value = 0;
    }
    this.i = {
      value,
      text: config.formatDate({
        date: value,
        formatStr: config.format,
        timezone: config.timezone
      })
    };
    if (typeof config.prettyText === 'function') {
      this.i.text = config.prettyText(this.i.text);
    }
    this.cdr.detectChanges();
    if (config.notify === 0 || _notify[value]) {
      this.ngZone.run(() => {
        this.callEvent('notify');
      });
    }
    if (value === 0) {
      this.ngZone.run(() => {
        this.status = CountdownStatus.done;
        this.destroy();
        this.callEvent('done');
      });
    }
  }
  /**
   * 获取倒计时剩余帧数
   */
  getLeft() {
    const {
      config,
      frequency
    } = this;
    let left = config.leftTime * 1000;
    const end = config.stopTime;
    if (!left && end) {
      left = end - new Date().getTime();
    }
    this.left = left - left % frequency;
  }
  ngOnInit() {
    this.init();
    if (!this.config.demand) {
      this.begin();
    }
  }
  ngOnDestroy() {
    this.isDestroy = true;
    this.destroy();
  }
  ngOnChanges(changes) {
    if (!changes.config.firstChange) {
      this.restart();
    }
  }
  static {
    this.ɵfac = function CountdownComponent_Factory(t) {
      return new (t || CountdownComponent)(i0.ɵɵdirectiveInject(LOCALE_ID), i0.ɵɵdirectiveInject(CountdownTimer), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(COUNTDOWN_CONFIG, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CountdownComponent,
      selectors: [["countdown"]],
      hostVars: 2,
      hostBindings: function CountdownComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("count-down", true);
        }
      },
      inputs: {
        config: "config",
        render: "render"
      },
      outputs: {
        event: "event"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([CountdownTimer]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 1,
      consts: [[4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "innerHTML"]],
      template: function CountdownComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CountdownComponent_Conditional_0_Template, 1, 4, "ng-container")(1, CountdownComponent_Conditional_1_Template, 1, 1);
        }
        if (rf & 2) {
          i0.ɵɵconditional(0, ctx.render ? 0 : 1);
        }
      },
      dependencies: [NgTemplateOutlet],
      styles: [".count-down{font-variant-numeric:tabular-nums}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CountdownComponent, [{
    type: Component,
    args: [{
      selector: 'countdown',
      template: `
    @if (render) {
    <ng-container *ngTemplateOutlet="render; context: { $implicit: i }" />
    } @else {
    <span [innerHTML]="i.text"></span>
    }
  `,
      host: {
        '[class.count-down]': 'true'
      },
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [NgTemplateOutlet],
      providers: [CountdownTimer],
      standalone: true,
      styles: [".count-down{font-variant-numeric:tabular-nums}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LOCALE_ID]
    }]
  }, {
    type: CountdownTimer
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.NgZone
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [COUNTDOWN_CONFIG]
    }]
  }], {
    config: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    render: [{
      type: Input
    }],
    event: [{
      type: Output
    }]
  });
})();
class CountdownModule {
  static {
    this.ɵfac = function CountdownModule_Factory(t) {
      return new (t || CountdownModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CountdownModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CountdownModule, [{
    type: NgModule,
    args: [{
      imports: [CountdownComponent],
      exports: [CountdownComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { COUNTDOWN_CONFIG, CountdownComponent, CountdownModule, CountdownStatus, CountdownTimer, provideCountdown };
